(() => {
  const integrationPrefix = 'drift__';

  const recordEvent = eventFunction => {
    if (window.HockeyStack) return eventFunction();

    if (!Array.isArray(window.hockeystackQueue)) {
      window.hockeystackQueue = [];
    }

    window.hockeystackQueue.push(() => eventFunction());
  };

  const listenToEvents = () => {
    window.drift.on('ready', () => {
      window.drift.on('emailCapture', event => {
        const email = event.data.email;

        if (email) {
          recordEvent(() => window.HockeyStack.identify(email));

          recordEvent(() => window.HockeyStack.goal(
            'Email Captured',
            { integration: 'Drift' }
          ));
        }
      });

      window.drift.on('startConversation', data => {
        const email = data.endUserEmail;
        const authorId = data.endUserId;

        recordEvent(() => window.HockeyStack.identify(email, { [integrationPrefix + 'author_id']: authorId }));

        recordEvent(() => window.HockeyStack.goal(
          'Conversation Started',
          {
            integration: 'Drift',
            [integrationPrefix + 'conversation_id']: data.conversationId
          }
        ));
      });

      window.drift.on('scheduling:requestMeeting', data => {
        recordEvent(() => window.HockeyStack.goal(
          'Meeting Requested',
          {
            integration: 'Drift',
            [integrationPrefix + 'assigned_agent']: data.teamMember.name
          }
        ));
      });

      window.drift.on('scheduling:meetingBooked', data => {
        recordEvent(() => window.HockeyStack.goal(
          'Meeting Booked',
          {
            integration: 'Drift',
            [integrationPrefix + 'conversation_id']: data.conversationId,
            [integrationPrefix + 'assigned_agent']: data.teamMember.name,
            [integrationPrefix + 'campaign_id']: data.campaignId,
            [integrationPrefix + 'meeting_booked_at']: new Date(data.meeting.time).toISOString()
          }
        ));
      });
    });
  };

  const getData = () => {
    if (!(timeout > 5000 || window.drift)) {
      timeout += 50;
      setTimeout(getData, 50);

      return;
    }

    if (window.drift) listenToEvents();

  };

  let timeout = 0;
  getData();
})();
